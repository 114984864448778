import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    components: {
        extraServiceRequest: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/extraServiceRequest"),
            loading: SkeletonBox,
        }),
        machineRequest: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/machineRequest"),
            loading: SkeletonBox,
        }),
        userRequest: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/userRequest"),
            loading: SkeletonBox,
        }),
    },

    data() {
        return {
            tabActiveName : "extraServiceRequest",
            debounceFnc: null,
            requestParam: {
                querySearch: '',
                orderBy: 'CREATED_AT',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
            isLoadingList: false
        };
    },
    created() {
        //this.getData(this.requestParam);
        //this.debounceFnc = debounce(() => {
        //    this.getData(this.requestParam);
        //}, 1000);
    },
    computed: {
        ...mapState({
            extraServiceRequestCount: (state) => state.extraServiceRequestCount,
        }),
    },

    methods: {
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.requestParam);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },
        // Get data
        getData(request) {
            
        },

    }
}